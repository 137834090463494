import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import configureStore from './configureStore';
import Axios from "axios";
import { CookiesProvider } from 'react-cookie';

export const {store, persistor} = configureStore();
// Axios.defaults.baseURL = 'https://p06kstu8tf.execute-api.ap-southeast-1.amazonaws.com/develop';
Axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;
// Axios.defaults.baseURL = 'http://localhost:8000';

Sentry.init({dsn: "https://54fa949a384a41b4991d339689c43f94@o318715.ingest.sentry.io/5281343"}); 

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

