import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import { setMallInfoValues, setSelectedMall } from "../Redux/Actions/actions";
import {getSelectedMallInfo} from "../Redux/Selectors/Malls";

const mapDispatchToProps = dispatch => {
    return {
        setMallInfo: mallsInfo => dispatch(setMallInfoValues(mallsInfo)),
        setSelectedMallInfo : selectedMall => dispatch(setSelectedMall(selectedMall))
    }
};

class MallSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mallsInfo : [],
            loadingDropdownValues : true,
            mallDropdownValue : "SMMG"
        }
        this.fetchMallsInfo = this.fetchMallsInfo.bind(this)
        this.mallDropdownHandleChange = this.mallDropdownHandleChange.bind(this)
    }

    componentDidMount() {
        this.axiosCancelSource = axios.CancelToken.source()
        this.fetchMallsInfo()
    }

    componentWillUnmount() {
        this.axiosCancelSource.cancel("Unmounted component. Axios request canceled.")
    }

    fetchMallsInfo() {
        if (!this.props.mallsInfo || this.props.mallsInfo.length === 0) {
            const _this = this;
            axios.get('/v2/malls/', {cancelToken: _this.axiosCancelSource.token}).then(response => {
                _this.setState({
                    loadingDropdownValues : false,
                },()=>{
                    _this.props.setMallInfo(response.data && response.data.malls);
                })
            })
        } else {
            this.setState({loadingDropdownValues: false})
        }
    }

    mallDropdownHandleChange(event) {
        const _this = this;
        _this.setState({
            mallDropdownValue : event.target.value
        },() => {
            _this.props.setSelectedMallInfo(_this.state.mallDropdownValue);
        })
    }

    renderDropdown() {
        const dropdownValues = this.state.loadingDropdownValues
            ? <option >Loading Values...</option>
            : this.props.mallsInfo.map(info => {
                return <option value={info.mallCode} key={info.mallCode}>{info.mallName}</option>
            })

        return <select className="custom-select mb-2" value={this.props.selectedMall} onChange={this.mallDropdownHandleChange} disabled={this.state.loadingDropdownValues}>
           { dropdownValues }
        </select>
    }
    render() {
        return (
            <React.Fragment>
                {this.renderDropdown()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = props => ({
    selectedMall: props.Malls.selectedMall,
    mallsInfo: props.Malls.mallsInfo
});

MallSelector = connect(mapStateToProps, mapDispatchToProps)(MallSelector);

export default MallSelector;