import React, {PureComponent, useCallback} from 'react';
import Axios from "axios";
import 'react-table/react-table.css'
import ReactTable from 'react-table'
import { faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import { setMallCode } from "../Redux/Actions/actions";

const mapDispatchToProps = dispatch => {
    return {
        setMallCodeValue: mallCode => dispatch(setMallCode(mallCode)),
    }
};

class MallStatus extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMallLevelStatusLoading :true,
            mallLevelStatusData: [],
            page: 0,
            filterText : '',
            filteredData : [],
            countDownTimer : 60,
            mallStatusColumns : [
                {
                  Header: 'Status',
                  id: 'isAlive',
                  accessor : (item) => { return this.renderStatus(item.isAlive)},
                  maxWidth: 150
                }, {
                  Header: 'Mall',
                  accessor: 'mallName'
                }, {
                  Header: 'Status Percentage',
                  id: 'statusPercentage',
                  accessor : (item) => { return this.renderPercentage(item.statusPercentage)},
                  maxWidth: 215,
                  sortMethod: (a,b) => {
                    if (parseInt(a) > parseInt(b)) {
                        return 1
                    }
                    if (parseInt(a) < parseInt(b)) {
                        return -1
                    }
                    return 0;
                  }                
                }, {
                  Header: 'Description', 
                  accessor: 'statusMessage'
                }, {
                    Header: '', 
                    id: 'mallCode',
                    accessor : (item) => { return this.renderButtonForAPperMall(item.mallCode)},
                    maxWidth: 175,
                    sortable : false
                }]
        }

        this.handleViewMoreDetailClick = this.handleViewMoreDetailClick.bind(this)
        this.handleOnPageChange = this.handleOnPageChange.bind(this)
        this.handleOnSortChange = this.handleOnSortChange.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
    }

    fetchMallLevelStatus() {
        this.setState({isMallLevelStatusLoading :true})        
        Axios.get('/monitoring/malls/').then(response => {
            this.setState({
                isMallLevelStatusLoading : false,
                mallLevelStatusData : response.data.status
            },()=>{
                if(!this.state.isMallLevelStatusLoading) {
                    this.apiCountdownCall()
                }
            })
        }).catch((error) => {
            console.info("ERROR",error)
        })
    }

    apiCountdownCall() {
        const apiCallInterval = setInterval(() => {
            this.setState(prevState =>{
                return {
                    countDownTimer : prevState.countDownTimer - 1
                }
            },()=>{
                if(this.state.countDownTimer === 0) {
                    this.fetchMallLevelStatus();
                    clearInterval(this.state.intervalId);
                    this.setState({ countDownTimer : 60})
                }
            })
        }, 1000);
        
        this.setState({intervalId: apiCallInterval})
    }

    renderButtonForAPperMall(mallCode) {
        return <button className={"btn btn-sm btn-primary "} onClick={()=>this.handleViewMoreDetailClick(mallCode) }>View More Details</button>
    }

    handleViewMoreDetailClick(mallCode){
        const _this = this;
        document.getElementById('ap_per_mall_container').scrollIntoView();
        _this.props.setMallCodeValue(mallCode);
    }

    componentDidMount(){
        this.fetchMallLevelStatus();
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    renderStatus(status) {
        return <FontAwesomeIcon icon={faCircle}  size='lg' color={status ? '#32CD32' : 'red'}/>
    }

    renderPercentage(value) {
        if(value) {
            return Math.round(value * 100) + "%"
        } else {
            return "0%"
        }
    }

    handleOnPageChange(page) {
        this.setState({
            page : page
        })
    }

    handleOnSortChange() {
        this.setState({
            page : 0
        })
    }

    handleFilterChange(e) {
        const { name, value } = e.target;
        let data = {...this.state.mallLevelStatusData}

        let filterData = Object.values(data).filter(row => {
            return row.mallName.toLowerCase().indexOf(value.toLowerCase()) !== -1 || row.mallCode.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })

        this.setState({ 
            [name]: value ,
            filteredData : filterData
        });
    }

    render() {
        let timerDisplay = this.state.isMallLevelStatusLoading ? 'Refreshing...' : <small>Table data will refresh after {this.state.countDownTimer} seconds</small>
        return (
            <React.Fragment>
                <div className="container-fluid mt-4">
                    <div className="row mb-2">
                        <div className="col-sm-5">
                            <h4>Mall Status</h4>
                        </div>
                        <div className="col-sm-7">          
                            <div className="row">
                                <div className="col-sm-4 p-0">
                                    Filter by Mall name or Mall code:
                                </div>
                                <div className="col-sm-8 pl-0">
                                    <input type="text" className="form-control" id="filterField" onChange={this.handleFilterChange} name="filterText" value={this.state.filterText} disabled={this.state.isMallLevelStatusLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-left">
                            {timerDisplay}
                        </div>
                    </div>
                    <ReactTable
                        loading={this.state.isMallLevelStatusLoading}
                        data={this.state.filterText ? this.state.filteredData: this.state.mallLevelStatusData}
                        columns={this.state.mallStatusColumns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        onPageChange={this.handleOnPageChange}
                        onSortedChange={this.handleOnSortChange}
                        page={this.state.page}
                        showPageJump={false}
                        noDataText="NO RECORDS FOUND"
                        defaultSorted = {[ {
                                    id: "isAlive",
                                    desc: true
                                }
                            ]}
                        />
                </div>
            </React.Fragment>
        )
    }
}

MallStatus = connect(null, mapDispatchToProps)(MallStatus);

export default MallStatus;