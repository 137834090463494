import React from 'react';
import axios from "axios";
import IframeResizer from "iframe-resizer-react";

class DashboardEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            embedUrl: null,
            loadError: false
        }
    }

    componentDidMount() {
        this.setState({
            embedUrl: null,
            loadError: false
        }, () => this.getEmbedUrl())
    }

    getEmbedUrl() {
        const _this = this;
        _this.setState({loadError: false}, () => {
            axios.get(_this.props.embedUrl ).then(response => {
                _this.setState({embedUrl: response.data.url+'&theme=night', loadError: false} );
            }).catch(() => {
                _this.setState({loadError: true})
            })
        })
    }

    render() {
        if (!this.state.embedUrl && !this.state.loadError) {
            return <p>Loading dashboard...</p>
        } else if (this.state.loadError) {
            return <p>
                Error loading dashboard.
                <button type="button" className={"btn btn-small btn-link"} onClick={this.getEmbedUrl.bind(this)}>Retry</button>
            </p>
        } else {
            return <IframeResizer
                src={this.state.embedUrl}
                frameBorder="0"
                width="100%"
                allowtransparency
            />
        }
    }
}

export default DashboardEmbed