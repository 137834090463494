export const SELECT_MALL = 'SELECT_MALL';
export const GET_LOGIN_TOKEN_AUTH = "GET_LOGIN_TOKEN_AUTH";
export const LOGOUT = 'LOGOUT';
export const GET_MALL_INFO_VALUES = 'GET_MALL_INFO_VALUES';
export const GET_SELECTED_MALL_INFO_VALUES = 'GET_SELECTED_MALL_INFO_VALUES';
export const GET_MALL_CODE = 'GET_MALL_CODE';

export const selectMall = mallIp =>({
    type: SELECT_MALL,
    mallIp
})

export const getLoginToken = token => ({
    type : GET_LOGIN_TOKEN_AUTH,
    token
})

export const doLogout = () => ({
    type: LOGOUT
})

export const setMallInfoValues = mallsInfo => ({
    type: GET_MALL_INFO_VALUES,
    mallsInfo
})

export const setSelectedMall = selectedMall => ({
    type: SELECT_MALL,
    selectedMall
})

export const setMallCode = mallCode => ({
    type: GET_MALL_CODE,
    mallCode
})