import React, {useState, useEffect} from 'react';
import axios from 'axios';


function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const AUTO_ADID_DOMAINS = [
    'sminvestments.com',
    'smsupermalls.com',
    'smprime.com',
]


export default function InviteUser() {
    const [invitedUsers, setInvitedUsers] = useState([])
    const [email, setEmail] = useState('')
    const [adid, setAdid] = useState('')
    const [formMessage, setFormMessage] = useState('')

    const isEmailOk = validateEmail(email);
    const emailExists = invitedUsers.filter(user => user.email === email).length > 0;
    const [emailName, emailDomain] = isEmailOk ? email.split('@') : [undefined, undefined]
    const autoAdid = isEmailOk && AUTO_ADID_DOMAINS.indexOf(emailDomain.toLowerCase()) >= 0 ? emailName : ''
    const effectiveAdid = adid ? adid : autoAdid

    const isAdidOk = !!effectiveAdid

    const getInvitedUsers = async () => {
        const result = await axios(
            '/api/invite/'
        );
        setInvitedUsers(result.data)
    }

    const addInvitedUser = (invitedUser) => {
        setInvitedUsers([...invitedUsers, invitedUser])
    }
    const inviteUser = async () => {
        const newEmail = email;
        const newAdid = effectiveAdid;

        setEmail('');
        setAdid('');
        try {
            setFormMessage('')
            const result = await axios.post(
                '/api/invite/', {"email": newEmail, "adid": newAdid}
            )
            addInvitedUser(result.data)
            setFormMessage("Invitation sent to " + newEmail)
        } catch(err) {
            setFormMessage(Object.keys(err.response.data).map(k => k + ": " + err.response.data[k]).join('; '))
        }
    }

    useEffect(() => {
        getInvitedUsers();
    }, [])

    const canSubmit = isEmailOk && isAdidOk && !emailExists

    return <div className="mb-5 pb-5">
        {formMessage && <p className="alert alert-info">{formMessage}</p>}
        <form className="form" onSubmit={() => {canSubmit && inviteUser()}}>
            <div className="form-group">
                <label>Email</label>
                <input className="form-control" type="email" placeholder="Email" value={email} onChange={e=>setEmail(e.currentTarget.value)}/>
            </div>
            <div className="form-group">
                <label>Active Directory ID</label>
                <input className="form-control" type="text" placeholder="Active Directory ID" value={effectiveAdid} onChange={e=>setAdid(e.currentTarget.value)}/>
            </div>
            <button className="btn btn-primary" type="button" disabled={!canSubmit} onClick={() => inviteUser()}>
                {emailExists ? 'User already invited' : 'Invite User'}
            </button>
        </form>
        <hr/>
        <div className="well">

            Invited Users
            <ul>
                {
                    invitedUsers.map(invitedUser => <li key={invitedUser.email}>
                        {invitedUser.email}
                    </li>)
                }
            </ul>
        </div>
    </div>
}

export function ModalWrapper(props) {
    return  <>
        <div className="modal show" style={{display: "block"}}  onClick={(e) => {
            if (e.target === e.currentTarget) {
                props.close && props.close();
            }
        }} style={{
            display: "block",
            position: "fixed",
            top: 0, bottom: 0, left: 0, right: 0,
            zIndex: 1000,
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                            props.close && props.close();
                        }}><span
                            aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    </>
}