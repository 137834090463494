import React from 'react';
import DateTimePicker from "react-datetime-picker";

const dateformat = require('dateformat');
const displayFormat = 'yyyy-mm-dd HH:MM TT';


export default class MallDisplayController extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedFloor: '',
            showAll: true,

            selectedPreset: 'live',
            selectedMapPreset: 'default',
            updateMaxDatetime: true,
            updateMaxDatetimeInterval: null
        }
    }

    updateMaxDatetime() {
        if (this.state && this.state.updateMaxDatetime && this.props.data && this.props.data.maxDatetime && this.props.onDataChange) {
            const newMaxDatetime = new Date(Date.now());
            if (parseInt(this.props.data.maxDatetime.getTime() / 1000) < parseInt(newMaxDatetime.getTime() / 1000)){
                this.props.onDataChange({
                    maxDatetime: newMaxDatetime
                });
            }
        }
    }

    componentDidMount() {
        const _this = this;

        const updateMaxDatetimeInterval = setInterval(_this.updateMaxDatetime.bind(this), 500);
        _this.setState({
            updateMaxDatetimeInterval: updateMaxDatetimeInterval
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.updateMaxDatetime);
    }

    handleHourlyClick() {
        this.setState({
            selectedPreset: 'hourly',
            updateMaxDatetime: true
        }, function () {
            const maxDatetime = new Date();
            this.props.onDataChange({
                currentDatetime: maxDatetime,
                stepMinutes: 60,
                rolloverMinutes: 24 * 60,
                maxDatetime: maxDatetime
            });
        })
    }

    handleLiveClick() {
        this.setState({
            selectedPreset: 'live',
            updateMaxDatetime: true,
        }, function () {
            const maxDatetime = new Date();
            this.props.onDataChange({
                currentDatetime: maxDatetime,
                stepMinutes: 1,
                rolloverMinutes: 10,
                maxDatetime: maxDatetime
            });
        })
    }

    handleChangeTime(dt) {
        this.setState({
            updateMaxDatetime: false,
            selectedPreset: 'manual',
        }, function () {
            this.props.onDataChange({
                currentDatetime: dt,
                maxDatetime: dt
            });
        })
    }

    handleRolloverMinutesChange(e) {
        this.props.onDataChange({
            rolloverMinutes: parseInt(e.target.value)
        })
    }


    handleStepMinutesChange(e) {
        this.props.onDataChange({
            stepMinutes: parseInt(e.target.value)
        })
    }

    handleTimeSpanChange(e) {
        this.props.onDataChange({
            timespan: parseInt(e.target.value)
        })
        this.setState({selectedMapPreset: "manual"});
    }

    handleMapFormatChange(e) {
        this.props.onDataChange({
            mapFormat: e.target.value
        });
        this.setState({selectedMapPreset: "manual"});
    }

    handleMapPresetChange(e) {
        const mapFormat = e.target.getAttribute('data-mapformat');
        const timespan = e.target.getAttribute('data-timespan');
        this.setState({selectedMapPreset: mapFormat}, function() {
            this.props.onDataChange({
                mapFormat: mapFormat,
                timespan: timespan
            })
        })
    }

    _getCurrentDateFromStr() {
        if (this.props.data.currentDatetime && this.props.data.timespan) {
            const dateFrom = new Date(this.props.data.currentDatetime.getTime() - (this.props.data.timespan * 60000));
            return dateformat(dateFrom, displayFormat);
        }
    }

    render() {
        if (!this.props.showControls) {
            return <div data-testid="controllerEmptyContainer"/>
        }
        return <div className="col p-3" data-testid="controllerContainer">
            <div className="form-inline">
                <span>Number of devices detected in &nbsp;</span>
                <select className="form-control form-control-sm"
                        value={this.props.data.timespan}
                        onChange={this.handleTimeSpanChange.bind(this)}>
                    <option value={3}>3 minutes</option>
                    <option value={15}>15 minutes</option>
                </select>
            </div>
            <div>
                from  <span data-testid="datetimeFromDisplay">{this._getCurrentDateFromStr()}</span><br/>
                to  <DateTimePicker
                value={this.props.data.currentDatetime}
                showLeadingZeros={true}
                clearIcon={null}
                maxDate={new Date()}
                format={"y-MM-dd hh:mm a"}
                disabled={this.state.is_live} onChange={this.handleChangeTime.bind(this)}/> { /* FIXME: limit the datetimepicker to 7 days ago only */ }
            </div>

            <div className={"pt-4"}>
                <h5>Animation Presets</h5>
                <label htmlFor={"radioHourly"} style={{paddingRight: "1em"}}
                       title={"Last 24 hours, 1 hour step, 1 hour slices"}>
                    <input
                        type={"radio"}
                        id={"radioHourly"}
                        checked={this.state.selectedPreset === 'hourly'}
                        data-testid="presetHourlyRadio"
                        onChange={this.handleHourlyClick.bind(this)}
                           // onchange={this.handleHourlyClick.bind(this)}
                    />
                    Last 24 hours
                </label>

                <label htmlFor={"radioLive"} style={{paddingRight: "1em"}}
                       title={"Last 10 minutes, 1 minute step, 1 hour slices"}>
                    <input
                        type={"radio"}
                        id={"radioLive"}
                        checked={this.state.selectedPreset === 'live'}
                        data-testid="presetLiveRadio"
                        onChange={this.handleLiveClick.bind(this)}
                           // onchange={this.handleLiveClick.bind(this)}
                    />
                    Live
                </label>
            </div>

            {/*<span style={{paddingRight: "2em"}}> { this.state.loadingText ? this.state.loadingText : '' } </span>*/}
            <h6 className={"pt-4"}>Animation options</h6>
            <div className={"form-inline"}>
                <small>
                    Start the animation &nbsp; {/* FIXME: change this into animate for X frames
                     instead of minutes to avoid stupid mistakes such as setting the animation speed to 15 and animation start at 40minutes ago */}
                    <input type={"number"}
                           data-testid="animationRolloverInput"
                           value={this.props.data.rolloverMinutes}
                           disabled={this.state.is_live}
                           onChange={this.handleRolloverMinutesChange.bind(this)}
                           className={"form-control form-control-sm"} style={{maxWidth: "5em"}}/> minutes <br/>from the current datetime selected.
                </small>
            </div>
            <div className={"form-inline"}>
                <small>
                    Animation speed is &nbsp;
                    <input type={"number"}
                           data-testid="animationSpeedInput"
                           value={this.props.data.stepMinutes}
                           disabled={this.state.is_live}
                           onChange={this.handleStepMinutesChange.bind(this)}
                           className={"form-control form-control-sm"} style={{maxWidth: "5em"}}/> minutes per frame.
                </small>
            </div>
        </div>
    }
}