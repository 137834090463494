import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faCircle, faPause, faPlay} from "@fortawesome/free-solid-svg-icons";
import MallsList from "./MallsList";
import MallDisplayController from "./MallDisplayController";
import MallDisplayMap from "./MallDisplayMap";
import {DENSITY_COLOR_HIGH, DENSITY_COLOR_LOW, DENSITY_COLOR_MEDIUM} from "../constants";
import Slider from 'react-input-slider';
import MallSelector from './MallSelector'
import {getSelectedMallInfo} from "../Redux/Selectors/Malls";
import BaseDisplay from "./BaseDisplay";
import HeatMapGraph from "./HeatMapGraph"

const dateformat = require('dateformat');
const format = 'yyyy-mm-dd HH:MM';
const displayFormat = 'yyyy-mm-dd hh:MM TT';


const granularity = 60000;  // 1 minute granularity  = 60 seconds * 1000 milleseconds
const utcOffsetHours = 8;  // hours
const utcOffsetMilliseconds = utcOffsetHours * 60 * 60 * 1000;  // hours * minutes * seconds * milliseconds

class MallDisplay extends BaseDisplay {
    state = {
        selectedFloor: 'all',
        showAll: true,
        ap: {},
        accessPointCounts: {},  // structure: {<mall>: {"<datetime as str>|<timespan>": {<accessPointName>: <count>}}}
        loadingText: "loading data",

        is_animating: false,
        showDebug: false,
        totalMallCounts: {},
        showMap: true,

        showControls: false,

        accessPoints: [],
        accessPointsByMallCode: {},

        controllerData: {
            currentDatetime: null,
            maxDatetime: new Date(),
            stepMinutes: 1,  // the number of minutes to step through for each iteration of the animation
            rolloverMinutes: 10,  // when max datetime is reached, rollover the data to the previous x minutes
            timespan: 15,
            mapFormat: "default"
        }
    };

    constructor(props) {
        super(props);

        this.UNSAFE_componentWillReceiveProps(props)
    }

    _setUpColorSwitcher() {
        const _this = this;
        const colorSwitcherInterval = setInterval(function () {
            _this.setState(prevState => {
                return {
                    ...prevState,
                    noValueColor: prevState.noValueColor === 'black' ? 'orange' : 'black'
                }
            })
        }, 500);
        this.setState({colorSwitcherInterval: colorSwitcherInterval})
    }

    _cleanupColorSwitcher() {
        clearInterval(this.state.colorSwitcherInterval);
    }

    componentWillUnmount() {
        this._ismounted = false;
        this._cleanupColorSwitcher();
        clearInterval(this.interval)
    }

    componentDidMount() {
        this._ismounted = true;

        const _this = this;

        const latestDate = this.state.controllerData.maxDatetime;
        this._setUpColorSwitcher()
        this.setState(prevState => {
            return {
                ...prevState,
                controllerData: {
                    ...prevState.controllerData,
                    currentDatetime: latestDate,
                }
            }
        });
        // this manages the animation
        _this.interval = setInterval(function () {
            if (!_this.state.is_animating) {
                return false;
            }
            _this._increaseCurrentDateTime(
                _this.state.controllerData.stepMinutes,
                _this.state.controllerData.rolloverMinutes)
        }, 200);
    }

    _increaseCurrentDateTime(minuteMultiplier, rolloverMinutes) {
        const _this = this;
        const latestDate = this.state.controllerData.maxDatetime;
        let newDateObj = new Date(this.state.controllerData.currentDatetime.getTime() + minuteMultiplier * granularity);
        if (newDateObj > latestDate) {
            if (rolloverMinutes) {
                newDateObj = new Date(this.state.controllerData.currentDatetime.getTime() - (rolloverMinutes * granularity));
            } else {
                newDateObj = latestDate;
            }
        }
        this.setState(prevState => {
            return {
                ...prevState,
                controllerData: {
                    ...prevState.controllerData,
                    currentDatetime: newDateObj,
                }
            }
        }, function () {
            _this.populateApCount(_this.state.controllerData.currentDatetime)
        })
    }

    currentDatetimeStr() {
        if (!this.state.controllerData.currentDatetime) {
            return '....'
        }
        return dateformat(this.state.controllerData.currentDatetime, format)
    }

    currentFromDatetime() {
        if (!this.state.controllerData.currentDatetime) {
            return null;
        }
        return new Date(this.state.controllerData.currentDatetime.getTime() - (this.state.controllerData.timespan * granularity))
    }

    shouldComponentUpdate(nextProps, nextState) {
        // do not re-render if only maxDatetime has changed
        return this.state.controllerData.maxDatetime.getTime() / 1000 === nextState.controllerData.maxDatetime.getTime() / 1000
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        const _this = this;
        if (this.props.mallInfo || nextProps.mallInfo) {
            _this.getAccessPointLocations(this.props.mallInfo ? this.props.mallInfo.mallCode : nextProps.mallInfo.mallCode);
            // _this.getMallInfo();
            _this._preloadAnimationData();
        }
    }

    populateApCount(dt) {
        // checks
        if (!dt || !this.state.controllerData.timespan || !this.props.selectedMall || !this.props.mallInfo) {
            return false;
        }

        const _this = this;
        const displayStr = dateformat(dt, format);
        const dateKey = displayStr + "|" + _this.state.controllerData.timespan;

        const dateTo = new Date(dt.getTime() - utcOffsetMilliseconds);  // offset the current datetime to UTC
        const dateFrom = new Date(dateTo.getTime() - (this.state.controllerData.timespan * granularity));

        const timeToStr = dateformat(dateTo, format);
        const timeFromStr = dateformat(dateFrom, format);

        const currentSelectedMall = this.props.selectedMall;
        const totalMallCountKey = currentSelectedMall + '|' + dateKey;
        // const currentSelectedMallIp = this.props.mallInfo.publicIp;
        const currentSelectedMallCode = this.props.mallInfo.mallCode;
        if (!_this.state.totalMallCounts.hasOwnProperty(totalMallCountKey)) {
            _this.setState(prevState => {
                return {
                    ...prevState,
                    totalMallCounts: {
                        ...prevState.totalMallCounts,
                        [totalMallCountKey]: 'loading...'
                    }
                }
            }, function () {
                axios.get(
                    '/v2/malls/' + currentSelectedMallCode + '/aps/ALL/mac_count/?time_from=' + timeFromStr + '&time_to=' + timeToStr + '&random=1&blacklist_hour=16'
                ).then(function (response) {
                    if (!response.data) {
                        console.error("No data response")
                        console.log("GOT: ", response, response.data)
                        _this.setState(prevState => {
                            return {
                                ...prevState,
                                totalMallCounts: {
                                    ...prevState.totalMallCounts,
                                    [totalMallCountKey]: 'Error in data loading'
                                },
                                loadingText: null
                            }
                        })
                    } else {
                        _this.setState(prevState => {
                            let newState = {...prevState};
                            newState.totalMallCounts[totalMallCountKey] = response.data.total_unique;
                            newState.loadingText = null;

                            if (!(currentSelectedMall in newState.accessPointCounts)) {
                                newState.accessPointCounts[currentSelectedMall] = {};
                            }
                            newState.accessPointCounts[currentSelectedMall][dateKey] = response.data.ap_counts;
                            return newState
                        })
                    }
                }).catch(reason=>{
                    console.error(reason);
                })
            })
        }
    }

    selectFloor(floorKey) {
        if (floorKey === 'all') {
            this.setState({showAll: true});
        } else {
            this.setState({selectedFloor: floorKey, showAll: false});
        }
    }

    getAccessPointCounts(mallIp, floorKey) {
        const timeSpanKey = this.currentDatetimeStr() + '|' + this.state.controllerData.timespan;

        if (!this.state.accessPointCounts.hasOwnProperty(this.props.selectedMall)
            || !this.state.accessPointCounts[this.props.selectedMall].hasOwnProperty(timeSpanKey)) {
            return 0
        }

        return Object.keys(this.state.accessPointCounts[this.props.selectedMall][timeSpanKey]).map(o => {
            const ap_count = this.state.accessPointCounts[this.props.selectedMall][timeSpanKey][o];
            return [ap_count, o];
        }).reduce((previousValue, currentValue) => {
            previousValue[currentValue[1]] = currentValue[0];
            return previousValue;
        }, {});
    }

    togglePlay() {
        const _this = this;
        this.setState({
            is_animating: !this.state.is_animating
        }, function () {
            if (_this.state.is_animating) {
                _this._preloadAnimationData()
            }
        })
    }

    animationTimeRange() {
        const timeRange = [];

        const maxDatetime = this.state.controllerData.maxDatetime;
        let activeDateTime = this.state.controllerData.maxDatetime;
        let previousCount = -1;

        if (activeDateTime) {
            while (previousCount < timeRange.length) {
                previousCount = timeRange.length;
                if (timeRange.indexOf(dateformat(activeDateTime, format)) === -1) {
                    timeRange.push(dateformat(activeDateTime, format));
                }
                activeDateTime = new Date(activeDateTime.getTime() + this.state.controllerData.stepMinutes * granularity);
                if (activeDateTime > maxDatetime) {
                    if (this.state.controllerData.rolloverMinutes) {
                        activeDateTime = new Date(maxDatetime.getTime() - (this.state.controllerData.rolloverMinutes * granularity));
                    } else {
                        activeDateTime = maxDatetime;
                    }
                }
            }
        }
        return timeRange.sort()
    }

    _preloadAnimationData() {
        this.animationTimeRange().map(t => this.populateApCount(new Date(t)));
    }

    toggleDebugShow() {
        this.setState(prevState => {
            return {...prevState, showDebug: !prevState.showDebug}
        })
    }

    handleControllerChangeData(newData) {
        const shouldRepopulateData = (
            // we are updating the current datetime
            newData.currentDatetime &&
            parseInt(newData.currentDatetime.getTime() / 1000) !== parseInt(this.state.controllerData.currentDatetime.getTime() / 1000)
        ) || (
            newData.maxDatetime &&
            parseInt(newData.maxDatetime.getTime() / 1000) !== parseInt(this.state.controllerData.maxDatetime.getTime() / 1000)
        ) || (
            newData.timespan && newData.timespan !== this.state.controllerData.timespan
        );
        const _this = this;
        this.setState(prevState => {
            const newState = {
                ...prevState,
                controllerData: {
                    ...prevState.controllerData,
                    ...newData
                }
            }
            if (newData.maxDatetime &&
                parseInt(this.state.controllerData.currentDatetime.getTime() / granularity) ===
                parseInt(this.state.controllerData.maxDatetime.getTime() / granularity)) {
                // if the current datetime is the same as the max datetime, on update max datetime, update the current time also
                newState.controllerData.currentDatetime = newData.maxDatetime;
            }
            return newState;
        }, function () {
            if (shouldRepopulateData) {
                _this._preloadAnimationData();
            }
        })
    }

    handleControlsToggle() {
        this.setState(prevState => {
            return {
                ...prevState,
                showControls: !prevState.showControls
            }
        })
    }

    deviceDistanceCalculator(apValue) {
        const installedApCount = this.props.mallInfo.declaredApPointsCount;  // FIXME: hardcoded for now; from philcom
        const commonAreaSize = this.props.mallInfo.commonArea;  // FIXME: hardcodedfor now; from philcom
        return 2 * Math.sqrt(commonAreaSize / (apValue * installedApCount * Math.PI))
    }

    generateFillColorFormula(apValue) {
        if (!apValue) {
            return 'white'
        }
        const distance = this.deviceDistanceCalculator(apValue);
        if (distance > 4.0) {
            return DENSITY_COLOR_LOW
        } else if (distance > 2.0) {
            return DENSITY_COLOR_MEDIUM
        } else {
            return DENSITY_COLOR_HIGH
        }
    }

    generateApDebugInfo(apValue) {
        if (!apValue) {
            return <span><br/>No devices</span>;
        }
        const distance = this.deviceDistanceCalculator(apValue);
        return <span><br/>Distance: {distance}</span>;
    }

    generateApRadiusSizeFormula() {
        if (this.state.controllerData.mapFormat === 'socialDistancing') {
            return (apValue, minRadius, maxRadius) => maxRadius
        }
    }

    getCurrentAccessPoints() {
        const apPoints = this.state.accessPointsByMallCode[this.props.mallInfo.mallCode];
        if (!apPoints) {
            this.getAccessPointLocations(this.props.mallInfo.mallCode);
            return [];
        }
        return apPoints;
    }

    renderHorizontalMap(mallIp, floorKey, isSingleMap) {
        const accessPointCounts = this.getAccessPointCounts(mallIp, floorKey);
        const floorAccessPoints = Object.values(this.getCurrentAccessPoints()).filter(o => o.floor === floorKey)
        const map = <MallDisplayMap
            svg={"/svg/" + this.props.mallInfo.mallCode + "/" + floorKey + ".svg"}
            floorAccessPoints={floorAccessPoints}
            accessPointCounts={accessPointCounts}
            floorViewBox={this.props.mallInfo.floorViewBox[floorKey]}
            fullHeight={!this.state.showAll}
            fillColorFormula={this.generateFillColorFormula.bind(this)}
            // apRadiusSizeFormula={this.generateApRadiusSizeFormula()}
            sumOfDevices={Object.values(accessPointCounts).reduce((a,b)=>a+b, 0)}
            totalUniqueDevices={this.state.totalMallCounts[this.props.selectedMall + '|' + this.currentDatetimeStr() + '|' + this.state.controllerData.timespan]}
            showDebug={this.state.showDebug}
            apDebugInfo={this.generateApDebugInfo.bind(this)}
        />;
        return this.buildMapContainer(mallIp, floorKey, isSingleMap, map)
    }

    handleSliderChange(e) {
        this.setState(prevState => {
            return {
                ...prevState,
                controllerData: {
                    ...prevState.controllerData,
                    currentDatetime: new Date(this.animationTimeRange()[e.x])
                }
            }
        })
    }

    render() {
        if (!this.props.mallInfo || !this.props.selectedMall) {
            return <div>
                <div className="row pt-3 pb-2">
                    <div className="col-12">
                        <HeatMapGraph/>
                        <hr/>
                    </div>
    
                    <div className="col-6">
                        <div className="row col-12">
                            <MallSelector/>
                        </div>
                    </div>
                </div>
            </div>
        }

        const map_render = <div>
            <div className="row controls-container">
                <MallDisplayController
                    floors={Object.keys(this.props.mallInfo.floorViewBox)}
                    showControls={this.state.showControls}
                    onDataChange={this.handleControllerChangeData.bind(this)}
                    data={this.state.controllerData}
                />
            </div>

            {this.buildMapRenders()}
        </div>;


        return <div>
            <div className="row pt-3 pb-2">
                <div className="col-12">
                        <HeatMapGraph/>
                    <hr/>
                </div>

                <div className="col-6">
                    <div className="row col-12">
                        <MallSelector/>
                    </div>
                    <div className="row col-12">
                        <p className="mb-0">
                            <span>
                                Total Unique Devices: <strong>{this.state.loadingText ? this.state.loadingText : this.state.totalMallCounts[this.props.selectedMall + '|' + this.currentDatetimeStr() + '|' + this.state.controllerData.timespan]}</strong>
                            </span>
                            <br/>
                            <small>
                                {dateformat(this.currentFromDatetime(), displayFormat)} to {dateformat(this.state.controllerData.currentDatetime, displayFormat)}
                            </small>
                        </p>
                    </div>
                </div>
                <div className={"col-6 text-right"}>

                    <div className="btn-group">
                        <button type="button"
                                className={"btn btn-primary controls-btn " + (this.state.is_animating ? 'disabled' : '')}
                                onClick={this.togglePlay.bind(this)}>
                            <FontAwesomeIcon icon={faPlay}/> Animate
                        </button>

                        <button type="button"
                                className={"btn btn-primary controls-btn " + (this.state.is_animating ? '' : 'disabled')}
                                onClick={this.togglePlay.bind(this)}>
                            <FontAwesomeIcon icon={faPause}/> Pause
                        </button>
                        <button type="button" className="btn btn-primary controls-btn" aria-expanded="false"
                                aria-controls="expandControls" onClick={this.handleControlsToggle.bind(this)}>
                            Advanced
                        </button>
                    </div>
                    <br/>
                    <Slider
                        x={this.animationTimeRange().indexOf(this.currentDatetimeStr())}
                        xmax={this.animationTimeRange().length - 1}
                        xmin={0}
                        onChange={this.handleSliderChange.bind(this)}
                    />
                </div>
            </div>
            <div>
                {map_render}
            </div>
            <div className={"row"}>
                <div className={"col-4"}>
                    <button type="button" className={"btn btn-link"} onClick={this.toggleDebugShow.bind(this)}
                            title={this.state.showDebug ? "Hide debug info" : "Show debug info"}>
                        <FontAwesomeIcon
                            icon={this.state.showDebug ? faCaretDown : faCaretRight}/> {this.state.showDebug ? "Hide debug info" : "Show debug info"}
                    </button>
                </div>
                <div className="col-8 text-right">
                    {this.renderLegend()}
                </div>
            </div>
            {this.renderDebug()}

        </div>
    }

    renderLegend() {
        const legendSpans = [];
        legendSpans.push(<span className="pl-4" key="legend-high">
                    <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={faCircle} color="gray"/>
                          <FontAwesomeIcon icon={faCircle} color={DENSITY_COLOR_HIGH} transform="shrink-3"/>
                    </span>
                    High Density
                </span>)
        legendSpans.push(<span className="pl-4" key="legend-medium">
                    <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={faCircle} color="gray"/>
                          <FontAwesomeIcon icon={faCircle} color={DENSITY_COLOR_MEDIUM} transform="shrink-3"/>
                    </span>
                    Medium Density
                </span>)
        legendSpans.push(<span className="pl-4" key="legend-low">
                    <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={faCircle} color="gray"/>
                          <FontAwesomeIcon icon={faCircle} color={DENSITY_COLOR_LOW} transform="shrink-3"/>
                    </span>
                    Low Density
                </span>)

        return <React.Fragment>
            <span><strong>Legend:</strong> &nbsp;</span>
            {legendSpans.map(o=>o)}

            <span className="pl-4">
                        <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} color="gray"/>
                              <FontAwesomeIcon icon={faCircle} color={this.state.noValueColor} transform="shrink-3"/>
                        </span>
                        No Data
                    </span>

        </React.Fragment>
    }

    renderDebug() {
        if (!this.state.showDebug) {
            return '';
        }

        const dtTimeSpanKey = this.currentDatetimeStr() + '|' + this.state.controllerData.timespan;

        if (!this.state.accessPointCounts[this.props.selectedMall] || !this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey]) {
            return 'Loading data for debug...'
        }

        const unassignedAccessPoints = Object.keys(this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey])
            .filter(apName => {
                const is_unassigned = Object.values(this.getCurrentAccessPoints()).filter(o => o.name === apName).length <= 0;
                const ap_current_count = this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][apName];
                return is_unassigned && ap_current_count > 0
            }).sort();
        const assignedZeroAccessPoints = Object.values(this.getCurrentAccessPoints())
            .map(ap => ap.name)
            .filter(apName => {
                const ap_current_count = this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][apName];
                return !ap_current_count || ap_current_count === 0;
            }).sort();
        const assignedNonzeroAccessPoints = Object.keys(this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey])
            .filter(apName => {
                const is_unassigned = Object.values(this.getCurrentAccessPoints()).filter(o => o.name === apName).length <= 0;
                const ap_current_count = this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][apName];
                return ap_current_count > 0 && !is_unassigned;
            }).sort();

        return <div>
            <strong>{this.props.selectedMall}</strong>
            {this.state.loadingText}
            <h4>AP List</h4>
            <strong>SUM of UNIQUE Mac per AP (no longer unique):
                {Object.keys(this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey])
                    .reduce((acc, o) => {
                        acc = acc + this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][o];
                        return acc;
                    }, 0)
                }
            </strong>
            <div className={"row"}>
                <div className={"col-3"}>
                    <h5>Unassigned Access Points with count ({unassignedAccessPoints.length})</h5>
                    <ul>
                        {unassignedAccessPoints.map(o => <li
                            key={'unassigned-' + o}>{o} - {this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][o]}</li>)}
                    </ul>
                </div>
                <div className={"col-3"}>
                    <h5>Assigned but zero count ({assignedZeroAccessPoints.length})</h5>
                    <ul>
                        {assignedZeroAccessPoints.map(o => <li
                            key={'zero-' + o}>{o} - {this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][o]}</li>)}
                    </ul>
                </div>
                <div className={"col-3"}>
                    <h5>Assigned non-zero count ({assignedNonzeroAccessPoints.length})</h5>
                    <ul>
                        {assignedNonzeroAccessPoints.map(o => <li
                            key={'ok-' + o}>{o} - {this.state.accessPointCounts[this.props.selectedMall][dtTimeSpanKey][o]}</li>)}
                    </ul>
                </div>
            </div>

            <MallsList/>

        </div>
    }
}

const mapStateToProps = props => ({
    selectedMall: props.Malls.selectedMall,
    mallInfo: getSelectedMallInfo(props.Malls)
});

MallDisplay = connect(mapStateToProps, null)(MallDisplay);
export default MallDisplay;