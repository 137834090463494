import React from 'react';
import {ImageOverlay, Map} from "react-leaflet";
import Leaflet from "leaflet";


export default class BaseMap extends React.PureComponent {
    onPopupClose() {
    }

    render() {
        // Renders map using 0,0 to 1000,1000 points
        let bounds;
        if (this.props.floorViewBox) {
            const viewBoxList = this.props.floorViewBox.split(' ');
            bounds = [
                [parseInt(viewBoxList[0]), parseInt(viewBoxList[1])],
                [-viewBoxList[3], parseInt(viewBoxList[2])],
            ];
        } else {
            bounds = [
                [0, 0],
                [-4000, 4000]
            ]
        }
        const extras = this.renderExtras();
        return <Map
            onPopupClose={this.onPopupClose.bind(this)}
            style={{
                height: this.props.fullHeight ? "70vh" : "32vh",
                position: "relative",
                maxHeight: "80vh",
                maxWidth: "100%"
            }}
            crs={Leaflet.CRS.Simple}
            center={[bounds[1][0] / 2, bounds[1][1] / 2]}
            zoom={this.props.fullHeight ? -2 : -3}
            minZoom={-5}
            scrollWheelZoom={false}
            fitBounds={bounds}
        >

            <ImageOverlay
                bounds={bounds}
                url={this.props.svg}
            />
            {extras}
        </Map>;
    }
}