import React from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import Select from 'react-select'

const DAYS_OF_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

class HeatMapGraph extends React.PureComponent {
    state = {
        fieldsData: undefined,
        graphData: {},
        isFieldsLoading: true,
        isGraphLoading: true,
        region : undefined,
        mall : undefined,
        lastUpdate : undefined,
        weeklyGraphApiHasError : false,
        hasGenericError : true
    }

    async componentDidMount(){
        const [fieldsDataResponse, graphResponse] = await Promise.all([
            axios.get('/api/filter-options/regions/'),
            axios.get('/api/counters/weekly/')
          ]).catch(error=>{
              this.setState({
                hasGenericError : true
              })
          });
          
        this.setState({
            fieldsData: fieldsDataResponse.data,
            graphData: graphResponse.data,
            isGraphLoading: false,
            isFieldsLoading: false,
            lastUpdate: new Date().toLocaleTimeString(),
            hasGenericError: false
          });
    }

    formatLegendDisplay(str) {
        return str.split('_').join(" ").toUpperCase()
    }

    sortOptions(arr) {
        let sortedArr = arr.sort((a,b)=> {
            let mallNameA = a.label.toUpperCase()
            let mallNameB = b.label.toUpperCase()

            if(mallNameA < mallNameB) {
                return -1;
            }

            if(mallNameA > mallNameB) {
                return 1;
            }

            return 0
        })

        return sortedArr
    }

    handleDropdownChange(optionValue,optionName) {
        const fieldName = typeof optionName === 'object' ? optionName.name : optionName;
        
        this.setState({
            [fieldName] : optionValue ? optionValue.value : undefined,
            isGraphLoading : true
        },()=>{
            axios.get('/api/counters/weekly/', {
                params: {
                    region : this.state.region,
                    mall : this.state.mall
                }
            }).then(response => {
                this.setState({
                    graphData: response.data,
                    isGraphLoading : false,                    
                    lastUpdate: new Date().toLocaleTimeString(),                    
                    weeklyGraphApiHasError : false
                })
            }).catch(error=>{
                console.error(error)
                this.setState({
                    weeklyGraphApiHasError : true
                })
            })
        })
    }

    renderFields() {     
        if(this.state.isFieldsLoading) {
            return <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status"> </div>
            </div>
        }

        if(!this.state.isFieldsLoading && this.state.hasGenericError) {
            return <div className='text-center'>
                 <h5>Encountered an error. Kindly try refreshing the page</h5>
            </div>
        }
        
        const regionOptions = this.state.fieldsData ? this.state.fieldsData.map(region => {
            return { 'label' : region.label, 'value' : region.value}
        }) : undefined

        const selectedRegions = this.state.region ? this.state.fieldsData.filter(option => {
            return option.value === this.state.region
        }) : this.state.fieldsData

        const mallsOptions = this.state.fieldsData ? selectedRegions.map(option => {
            return option.malls.map(mall => {
                return { 'label' : mall.label, 'value' : mall.value, 'search' : mall.search}
            })
        }).flat() : undefined

        // this constants value to show the value selected in the UI part, select dropdown
        const regionValue = regionOptions.filter(option => option.value === this.state.region)        
        const mallsValue =  mallsOptions.filter(option => option.value === this.state.mall)
        
        return <div className='row'>
            <div className='col-2 pl-1'>
                <h4>Cumulative Count</h4> 
                <h4>{ !this.state.isGraphLoading && this.convertNumberToHumanReadableStr(this.state.graphData.cumulative_count)}</h4>
            </div>
            <div className='col-8 offset-sm-2 justify-content-center'>
                <div className='row'>
                    <div className='col-4'>
                        <div className="row">
                            <div>Region:</div> 
                            <div className='col'><Select options={this.sortOptions(regionOptions)} 
                                value={regionValue} name="region" onChange={this.handleDropdownChange.bind(this)}
                                isClearable={true} placeholder="All" className="react-container" classNamePrefix="react-select" /></div>
                        </div>
                    </div>
                    <div className='col-4'>
                    <div className="row">
                            <div>Malls:</div> 
                            <div className='col'><Select options={this.sortOptions(mallsOptions)} 
                                value={mallsValue} name="mall" onChange={this.handleDropdownChange.bind(this)}
                                isClearable={true} placeholder="All" className="react-container" classNamePrefix="react-select" /></div>
                        </div>
                    </div>  
                </div>              
            </div>
        </div>
    }

    convertNumberToHumanReadableStr(number) {
        const SUFFIXES = {
            '12': 'T',
            '9' : 'B',
            '6' : 'M',
            '3' : 'K',
            '0' : ''
        }
    
        function getExponent(n) {
            if(n===0) {
                return 0;
            }
    
            return Math.floor(Math.log10(Math.abs(n)));
        }
    
        function precise(n) {
            return Number.parseFloat(n.toPrecision(3));
        }
    
        function toHumanString(sn) {
            var n = precise(Number.parseFloat(sn));
            var e = Math.max(Math.min(3 * Math.floor(getExponent(n) / 3), 24), -24);
            return precise(n / Math.pow(10, e)).toString() + SUFFIXES[e];
        }
    
        return toHumanString(number)
    }

    renderGraph(){  
        if(this.state.weeklyGraphApiHasError) {
            return <div className='text-center border py-5'>
                 <h5>Encountered an error while fetching graph data. Kindly try refreshing the page</h5>
            </div>
        }

        const today = new Date().toLocaleString('en-us', {  weekday: 'long' })
        const todayDeviceValue = Object.keys(this.state.graphData).length > 0 ?  this.state.graphData['this_week'][today.toLowerCase()] : 0
        const seriesData = Object.keys(this.state.graphData).map(key => {
            if(key !== 'cumulative_count') {
                return {                
                    name: this.formatLegendDisplay(key),
                    type: 'line',
                    data: DAYS_OF_WEEK.map(day => {
                        return this.state.graphData[key][day.toLowerCase()]
                    }),
                    markLine : {
                        silent: true,
                        label : {
                            show : false
                        },
                        symbol : 'none',
                        data : key === 'this_week' ? [{name: 'currentDay', xAxis: today}] : []
                    }
                }
            } else {
                return null
            }
        })
        
        return <>
            <ReactEcharts
                loadingOption= {{ lineWidth: 3 }}
                showLoading={ this.state.isGraphLoading }
                hideLoading={ !this.state.isGraphLoading }  
                notMerge={true}
                lazyUpdate={true}
                option={
                    {
                        title: { text: 'Daily Device Count'},   
                        legend: {},
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            type: 'category',
                            data: DAYS_OF_WEEK,
                            axisPointer: {
                                show: true,
                                type: "line"
                            }
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: seriesData
                    }
                } 
            />
            <div className='col-12'>
                {this.state.isGraphLoading ? null : <div>
                    Today's device count is <span className='h6'> {todayDeviceValue.toLocaleString()} </span> as of { this.state.lastUpdate }
                </div> }
            </div>
        </>
    }

    render() {
        return (
            <>
                <div className='container-fluid pb-4'>
                    {
                        this.renderFields()
                    }
                </div>
                {
                    this.renderGraph()
                }   
            </>
        )
    }
}

export default HeatMapGraph