import React from 'react';
import axios from 'axios';
import mall2ip from '../Resources/mall2ip.json';
import { connect } from 'react-redux'
import { selectMall } from "../Redux/Actions/actions";

const STATIC_RESPONSE_DATA = {"malls": ["58.69.27.194", "202.57.58.218", "58.69.27.66", "100.79.0.31", "202.57.40.18", "203.177.9.130", "122.55.206.2", "202.57.45.90", "122.53.154.66", "202.57.39.34", "122.55.206.162", "122.53.131.34", "202.57.45.114", "122.53.130.98", "202.57.45.75", "122.55.202.194", "202.57.45.138", "202.57.42.67", "202.57.39.42", "112.198.27.2", "202.57.44.202", "192.168.254.2", "202.57.45.171", "45.64.122.218", "202.57.41.211", "202.57.45.10", "202.57.39.86", "202.57.45.203", "122.55.206.50", "122.55.206.226", "202.57.45.147", "202.57.45.107", "202.57.45.161", "122.55.176.34", "202.57.51.218", "202.57.45.51", "122.52.251.226", "202.57.38.234", "119.92.169.2", "202.57.41.202", "202.57.39.62", "202.57.62.114", "202.57.36.138", "202.57.45.218", "112.198.27.52", "202.57.44.147", "100.79.0.24", "112.198.31.2", "202.57.44.171", "122.3.251.66", "202.57.45.1", "112.198.27.3", "202.57.46.138", "202.57.45.194", "122.52.254.34", "202.57.45.82", "122.55.202.98", "199.169.1.2", "124.6.182.162", "202.57.39.130", "202.57.44.218"]};
const MALL_WHITELIST = [
    '202.57.45.51'
];


class MallsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            malls: [],
            ip2mall: {}
        }
    }

    componentDidMount() {
        const _this = this;
        if (!STATIC_RESPONSE_DATA) {
            axios.get('/mall/').then(function (response) {
                _this.setState({malls: response.data.malls})
            })
        }

        const ip2mall = Object.values(mall2ip).reduce((obj, row) => {
            obj[ row.ip ] = row.name;
            return obj
        }, {});
        _this.setState({ip2mall: ip2mall});
        if (STATIC_RESPONSE_DATA) {
            _this.setState({malls: STATIC_RESPONSE_DATA.malls})
        }
    }

    selectMall(mallIp) {
        this.props.selectMall(mallIp);
    }

    render() {
        return <div>
            <h2>Mall List</h2>
            <ul>
                { this.state.malls.filter(o => MALL_WHITELIST.length === 0 || MALL_WHITELIST.indexOf(o) >= 0).map(o => <li  key={o} >
                    <a onClick={this.selectMall.bind(this, o)}><strong>{this.state.ip2mall[o]}</strong> ({o}</a>)
                </li>)}
            </ul>
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    selectMall: mallIp => dispatch(selectMall(mallIp))
});

MallsList = connect(null, mapDispatchToProps)(MallsList);

export default MallsList;