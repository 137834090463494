import React from 'react';
import {Circle, Popup} from "react-leaflet";
import BaseMap from "./BaseMap";


export default class MallDisplayMap extends BaseMap {
    state = {
        showDebug: false,
        noValueColor: "black",
        colorSwitcherInterval: null
    }

    _setUpColorSwitcher() {
        const _this = this;
        const colorSwitcherInterval = setInterval(function() {
            _this.setState(prevState => {
                return {
                    ...prevState,
                    noValueColor: prevState.noValueColor === 'black' ? 'orange' : 'black'
                }
            })
        }, 500);
        this.setState({colorSwitcherInterval: colorSwitcherInterval})
    }

    _cleanupColorSwitcher() {
        clearInterval(this.state.colorSwitcherInterval);
    }

    componentDidMount() {
        this._setUpColorSwitcher();
    }

    componentWillUnmount() {
        this._cleanupColorSwitcher();
    }

    smallestDimensionSize() {
        // this assumes that the viewbox is in format "x1 y1 x2 y2";
        let ySize = 4000;
        let xSize = 4000;

        if (this.props.floorViewBox) {
            const viewBoxList = this.props.floorViewBox.split(' ').map(o => parseInt(o));
            ySize = viewBoxList[3] - viewBoxList[1];
            xSize = viewBoxList[2] - viewBoxList[0];
        }


        return ySize > xSize ? xSize : ySize;
    }

    maxCircleRadius(smallestDimension) {
        // smallest dimension could be the length or width of the SVG file
        return smallestDimension * 0.11;  // 12 % of the total
    }

    minCircleRadius(smallestDimension) {
        // smallest dimension could be the length or width of the SVG file
        return smallestDimension * 0.04;  // 4%
    }

    disabledRadius(smallestDimension) {
        return smallestDimension * 0.02;  // 2%
    }

    apValueAdjusted(apValue) {
        // 3/2 == ratio of mall goers with and without devices
        // sum of devices / total unique devices = to adjust for devices found in multiple access points
        return (apValue /(this.props.sumOfDevices/this.props.totalUniqueDevices)) * (3/2);
    }
    apValueForSizing(apValue, allowedMaxApValue) {
        const adjustedApValue = this.apValueAdjusted(apValue)
        return adjustedApValue > allowedMaxApValue ? allowedMaxApValue : adjustedApValue;
    }

    apRadiusSize(apValue, allowedMaxApValue, minRadius, maxRadius) {
        const apValueForSizing = this.apValueForSizing(apValue, allowedMaxApValue)
        if (this.props.apRadiusSizeFormula) {
            return this.props.apRadiusSizeFormula(apValueForSizing, minRadius, maxRadius)
        }
        return minRadius + ((maxRadius - minRadius) * (apValueForSizing/allowedMaxApValue));
    }

    fillColor(apValue) {
        const adjustedApValue = this.apValueAdjusted(apValue)
        return apValue > 0 ? (this.props.fillColorFormula ? this.props.fillColorFormula(adjustedApValue) : "red") : this.state.noValueColor
    }

    _getCircles() {
        // megamall size in sqm:  67,224.13
        // formula is: Distance = 2*sqrt( C / (L*N*pi) )
        // C = Common Area
        // L = # of Listed APs
        // N = number of devices detected in AP

        const allowedMaxApValue = 100;
        const smallestDimensionSize = this.smallestDimensionSize()
        const maxCircleSize = this.maxCircleRadius(smallestDimensionSize);
        const minCircleSize = this.minCircleRadius(smallestDimensionSize);
        const noValueCircleSize = this.disabledRadius(smallestDimensionSize);

        // FIXME: refactor circle generation into another code; currently ALL circles are recomputed each time we do a color transition for novalues
        const apNameCounters = {};
        return Object.values(this.props.floorAccessPoints).map(ap => {
            const apValue = this.props.accessPointCounts[ap.name];
            const radiusSize = apValue > 0 ? this.apRadiusSize(apValue, allowedMaxApValue, minCircleSize, maxCircleSize) : noValueCircleSize;
            // const xy = this.xy([m[0], m[1]], bounds, horizontalBounds, true, [100,100,100,100]);
            let xy;
            if (typeof(ap.coordinates) === "string") {

                const apRawTransform = ap.coordinates.split(',')
                xy = [-parseFloat(apRawTransform[5].split(')')[0]), parseFloat(apRawTransform[4]) ]
            } else {
                const [coordX, coordY] = ap.coordinates
                xy = [-(coordY*4), coordX*4]  // somehow this is rotated in leaflet
            }
            if (!radiusSize) {
                return null
            }
            if (apNameCounters.hasOwnProperty(ap.name)) {
                apNameCounters[ap.name] += 1
            } else {
                apNameCounters[ap.name] = 1
            }
            const key = ap.name;
            return <Circle
                key={ap.name + '-' + apNameCounters[ap.name]}
                center={xy}
                radius={radiusSize}
                fillColor={this.fillColor(apValue)}
                color={"gray"}
                fillOpacity={0.5}
                opacity={0.5}
                weight={1}
            >
                <Popup>{ap.name} - <strong>{apValue}</strong>
                    {this.props.showDebug && <div>
                        Coordinates: {xy[0]}, {xy[1]} <br/>
                        Radius Size: {radiusSize} <br/>
                        Scaled AP Value: {this.apValueAdjusted(apValue)}
                        {this.props.apDebugInfo && this.props.apDebugInfo(this.apValueAdjusted(apValue))}
                    </div>}</Popup>
            </Circle>
        });
    }

    renderExtras() {
        return this._getCircles();
    }
}