import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux'
import MallSelector from './MallSelector'
import {getSelectedMallInfo} from "../Redux/Selectors/Malls";
import PathAnalysisMap from "./PathAnalysisMap";
import BaseDisplay from "./BaseDisplay";

class PathAnalysisDisplay extends BaseDisplay {
    state = {
        selectedFloor: 'all',
        showAll: true,
        ap: {},
        loadingText: "loading data",

        accessPoints: [],
        accessPointsByMallCode: {},
        pathsByMallCode: {}
    };

    constructor(props) {
        super(props);

        this.UNSAFE_componentWillReceiveProps(props)
    }


    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidMount() {
        this._ismounted = true;

        const _this = this;
    }

    getPaths(mallCode) {
        const _this = this;
        if (!this.state.pathsByMallCode.hasOwnProperty(mallCode)) {
            axios.get('/path-analysis/' + mallCode).then(
                response => {
                    _this.setState(prevState => {
                        return {
                            ...prevState,
                            pathsByMallCode: {
                                ...prevState.pathsByMallCode,
                                [mallCode]: response.data.result
                            }
                        }
                    })
                }
            )
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        const _this = this;
        if (this.props.mallInfo || nextProps.mallInfo) {
            _this.getAccessPointLocations(nextProps.mallInfo ? nextProps.mallInfo.mallCode: this.props.mallInfo.mallCode);
            _this.getPaths(nextProps.mallInfo ? nextProps.mallInfo.mallCode: this.props.mallInfo.mallCode)
        }
    }

    selectFloor(floorKey) {
        if (floorKey === 'all') {
            this.setState({showAll: true});
        } else {
            this.setState({selectedFloor: floorKey, showAll: false});
        }
    }


    getCurrentAccessPoints() {
        const apPoints = this.state.accessPointsByMallCode[this.props.mallInfo.mallCode];
        if (!apPoints) {
            this.getAccessPointLocations(this.props.mallInfo.mallCode);
            return [];
        }
        return apPoints;
    }

    getAvailablePaths() {
        const paths = this.state.pathsByMallCode[this.props.mallInfo.mallCode];
        if (!paths) {
            return [];
        }
        return paths;
    }

    renderHorizontalMap(mallIp, floorKey, isSingleMap) {
        const floorAccessPoints = Object.values(this.getCurrentAccessPoints()).filter(o => o.floor === floorKey)
        const floorApNames = Object.values(floorAccessPoints).map(apObject => apObject.name)
        const floorPaths = Object.values(this.getAvailablePaths()).filter(
            o => floorApNames.indexOf(o.apname) > -1 && floorApNames.indexOf(o.destination_ap) > -1  // TODO: handle cross floor paths
        )
        const maxPathCount = Math.max(
            ...Object.values(floorPaths).map(o => parseInt(o.count) )
        )

        const map = <PathAnalysisMap
            svg={"/svg/" + this.props.mallInfo.mallCode + "/" + floorKey + ".svg"}
            floorAccessPoints={floorAccessPoints}
            floorViewBox={this.props.mallInfo.floorViewBox[floorKey]}
            fullHeight={!this.state.showAll}
            floorPaths={floorPaths}
            pathMaxCount={maxPathCount}
            // apRadiusSizeFormula={this.generateApRadiusSizeFormula()}
        />;
        return this.buildMapContainer(mallIp, floorKey, isSingleMap, map);
    }

    render() {
        if (!this.props.mallInfo || !this.props.selectedMall) {
            return <div>
                <div className="row pt-3 pb-2">
                    <div className="col-6">
                        <div className="row col-12">
                            <MallSelector/>
                        </div>
                    </div>
                </div>
            </div>
        }

        const map_render = this.buildMapRenders();


        return <div>
            <div className="row pt-3 pb-2">
                <div className="col-6">
                    <div className="row col-12">
                        <MallSelector/>
                    </div>
                </div>
            </div>
            <div>
                {map_render}
            </div>
        </div>
    }

}

const mapStateToProps = props => ({
    selectedMall: props.Malls.selectedMall,
    mallInfo: getSelectedMallInfo(props.Malls)
});

PathAnalysisDisplay = connect(mapStateToProps, null)(PathAnalysisDisplay);
export default PathAnalysisDisplay;