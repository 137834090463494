import React, { PureComponent } from 'react';
import OtpInput from "react-otp-input";
import WifiHotspotsNavBrand from "../Resources/ImageAssets/wifi-dashboard-horizontal-darkbg.png";
import {getLoginToken } from "../Redux/Actions/actions";
import {connect} from "react-redux";
import axios from "axios";

const mapDispatchToProps = dispatch => {
    return {
        setToken: token => dispatch(getLoginToken(token))
    }
};


class Login extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            OTP : undefined,
            email : '',
            password : '',  
            errorMsg : '',
            isLoading : false,
        }

        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleOTPChange(otp) {
        this.setState({
            OTP : otp
        })
    }

    handleInputChange(e){
        const { name, value } = e.target;
        this.setState({ 
            [name]: value 
        });
    }

    handleKeyPress(e) {
        if (e.which === 13 || e.keyCode === 13) {
            e.preventDefault();
            document.getElementById('sign_in_btn').click();
        }
    }

    handleLoginSubmit(e) {
        const tokenUrl = '/api/token/'

        const _this = this;
        if(e) {
            e.preventDefault();
        }

        this.setState({
            isLoading : true
        })

        axios.post(tokenUrl,{
            // otp : this.state.OTP,
            username : this.state.email,
            password : this.state.password,
        }).then(function(response) {
            _this.setState({
                isLoading : false,                
                errorMsg : ''
            })
            
            _this.props.setToken(response.data.access)
        }).catch(function(error) {
            console.error("error", error)
            console.error("error.response", error.response)
            const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : undefined;
            _this.setState({
                errorMsg : errorMessage ? errorMessage : 'Something went wrong. Contact the admin',
                isLoading : false
            })

            if (!errorMessage) {
                throw error
            }
        });     

        return false
    }

    render() {
        // const isDisabled = (this.state.OTP && this.state.OTP.length < 6) || !this.state.OTP ? true : false;
        const isDisabled = this.state.email === '' || this.state.password === '' ? true : false;        

        return (
                <form className="form-signin border border-info mx-auto" onKeyPress={this.handleKeyPress} onSubmit={this.handleLoginSubmit}>  
                    <div className="login-header text-center px-3 py-4">
                        <img src={WifiHotspotsNavBrand} className="d-inline-block align-top" alt="wifi-hotspots.png" style={{ 'maxWidth': '100%'}} />
                    </div>
                    <div className="p-2">
                        <h6 className="my-3 font-weight-normal">Sign in with your email and password
                        <br/>
                        
                        <small>Note: You can use your active directory credentials</small>
                        </h6>
                        

                        <label htmlFor="inputEmail" className="sr-only">Email Address</label>
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" 
                            name="email" onChange={this.handleInputChange} value={this.state.email} required autoFocus/>

                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" 
                            name="password" onChange={this.handleInputChange} value={this.state.password} required />
                    </div>
                    <div className="text-center">
                                    {/* <OtpInput
                                    containerStyle="otp-container"
                                    onChange={this.handleOTPChange}
                                    value={this.state.OTP}
                                    numInputs={6}
                                    separator={<span className="mx-2"></span>}
                                />
                                <span className="text-muted"><small>Enter OTP Here</small></span> */}
                                <br/>
                                <span className="text-danger"><small><strong>{this.state.errorMsg}</strong></small></span>
                        </div>
                    <div className="px-3 py-2 text-center">
                        {
                            this.state.isLoading ? 
                            <div className="spinner-border text-primary"  role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        :
                            <button className="btn btn-lg btn-primary btn-block mt-4 mb-2" id="sign_in_btn" type="submit" disabled={isDisabled}>
                                Sign in
                            </button>
                        }

                    </div>
                </form>
        )
    }
}


Login = connect(null, mapDispatchToProps)(Login);
export default Login