export const LOGIN_URL="https://wifi-dashboard-portal2.auth.ap-southeast-1.amazoncognito.com/login?client_id=7ts28nr3gguse1em5njhdlc82k&response_type=code&scope=email+openid+profile&redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname;

export const FLOOR_MAPPING = {
    "lowerground": 1,
    "upperground": 1.5,
    "basement": -1,
    "2f": 2,
    "l2": 2,
    "3f": 3,
    "l3": 3,
    "4f": 4,
    "l4": 4,
    "5f": 5,
    "l5": 5
}

export const DENSITY_COLOR_HIGH = 'red';
export const DENSITY_COLOR_MEDIUM = 'darkorange';
export const DENSITY_COLOR_LOW = 'green';
