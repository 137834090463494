import initialState from "../initialState";
import {SELECT_MALL, GET_MALL_INFO_VALUES, GET_MALL_CODE } from '../Actions/actions';

function Malls(state, action) {
    if (typeof state === 'undefined') {
        state = JSON.parse(JSON.stringify(initialState.Malls))
    }
    if (typeof action === 'undefined') {
        action = {}
    }

    switch(action.type) {
        case SELECT_MALL :
            return {
                ...state,
                selectedMall: action.selectedMall
            }

        case GET_MALL_INFO_VALUES:
            if (JSON.stringify(state.mallsInfo) === JSON.stringify(action.mallsInfo)) {
                // we already have this data, why save it again?
                return state
            }
            return {
                ...state,
                mallsInfo : action.mallsInfo
            }
        case GET_MALL_CODE : 
            return {
                ...state,
                mallCode : action.mallCode,
                mallName : action.mallName
            }
            
        default : 
        return state;
    }
}

export default Malls;