import {combineReducers, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LoginTokenReducer from "./Redux/Reducers/LoginTokenReducer";
import Malls from "./Redux/Reducers/Malls";

export const RootReducer = combineReducers({
    LoginTokenReducer,
    Malls,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["LoginTokenReducer"]
}
 
const persistedReducer = persistReducer(persistConfig, RootReducer)
 
export default () => { 
  let store = createStore(persistedReducer);
  let persistor = persistStore(store)
  return { store, persistor }
}