import React from 'react';
import axios from "axios";
import {FLOOR_MAPPING} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchMinus, faSearchPlus} from "@fortawesome/free-solid-svg-icons";


export default class BaseDisplay extends React.Component {
    getAccessPointLocations(mallCode) {
        const _this = this;
        const currentMallCode = mallCode ? mallCode : _this.props.mallInfo.mallCode;
        if (!this.state.accessPointsByMallCode.hasOwnProperty(currentMallCode)) {
            _this.setState(prevState => {
                return {
                    ...prevState,
                    accessPointsByMallCode: {
                        ...prevState.accessPointsByMallCode,
                        [currentMallCode]: null
                    }
                }
            }, () => {
                axios.get('/v2/malls/' + currentMallCode + '/access_points/').then(function (response) {
                    _this.setState(prevState => {
                        return {
                            ...prevState,
                            accessPointsByMallCode: {
                                ...prevState.accessPointsByMallCode,
                                [currentMallCode]: response.data.access_points
                            }
                        }
                    })
                })
                // TODO: on error of axios or no value is retrieved, delete the key for currentMallCode
            })
        }
    }

    buildMapRenders() {
        return <div style={{position: "relative"}} className="row col m-0 p-0 map-container-bordered">
            {
                this.state.showAll ?
                    Object.keys(this.props.mallInfo.floorViewBox).sort((a, b) => {
                        return FLOOR_MAPPING[a.toLowerCase()] - FLOOR_MAPPING[b.toLowerCase()]
                    }).map(
                        k => this.renderHorizontalMap(this.props.selectedMall, k)
                    ) :
                    this.renderHorizontalMap(this.props.selectedMall, this.state.selectedFloor, true)
            }
        </div>;
    }

    buildMapContainer(mallIp, floorKey, isSingleMap, map) {
        return <div key={mallIp + floorKey + "|" + (isSingleMap ? 'single' : 'multiple')}
                    className={(this.state.showAll ? "col-4" : "col") + ' map-container-bordered'}
                    style={{padding: "0.75em"}}>
            <h5>
                {floorKey}
                {isSingleMap
                    ? <button className={"btn-link btn pb-0 bt-0"} onClick={this.selectFloor.bind(this, 'all')}>
                        <FontAwesomeIcon icon={faSearchMinus}/></button>
                    : <button className={"btn-link btn pb-0 pt-0"} onClick={this.selectFloor.bind(this, floorKey)}>
                        <FontAwesomeIcon icon={faSearchPlus}/></button>}
            </h5>

            {map}
        </div>
    }
}