import React, {Component} from 'react';
import MallDisplayMap from "./MallDisplayMap";
import {DENSITY_COLOR_LOW} from "../constants";


export default class TestMap extends Component {
    state = {
        "mallCode": "SMPN",
        "floorKey": "SMPN_LG",
        floorAccessPoints: "",
        floorWidth: 4000,
        floorHeight: 4000
    }

    generateFillColorFormula(apValue) {
        if (!apValue) {
            return 'white'
        }

        return DENSITY_COLOR_LOW

    }

    parseAccessPoints() {
        if (!this.state.floorAccessPoints) {
            return []
        }

        try {
            return JSON.parse(this.state.floorAccessPoints)
        } catch (e) {
            console.error(e)
            return []
        }
    }

    render() {
        const accessPoints = this.parseAccessPoints()
        const viewBox = "0 0 " + this.state.floorWidth + " " + this.state.floorHeight
        const svgImg = <img src={"/svg/" + this.state.mallCode + "/" + this.state.floorKey + ".svg"}/>
        console.log("svgImage", svgImg)
        console.log(viewBox)
        return <>
            mall code <input type="text" value={this.state.mallCode}
                             onChange={(e) => this.setState({mallCode: e.target.value})}/>
            floor key <input type="text" value={this.state.floorKey}
                             onChange={(e) => this.setState({floorKey: e.target.value})}/>
            floor access points <textarea
            onChange={e => this.setState({floorAccessPoints: e.target.value})}>{this.state.floorAccessPoints}</textarea>
            floor width <input type="text" value={this.state.floorWidth}
                               onChange={(e) => this.setState({floorWidth: e.target.value})}/>
            floor height <input type="text" value={this.state.floorHeight}
                                onChange={(e) => this.setState({floorHeight: e.target.value})}/>

            <MallDisplayMap
                key={"testmap"}
                svg={"/svg/" + this.state.mallCode + "/" + this.state.floorKey + ".svg"}
                floorAccessPoints={accessPoints}
                accessPointCounts={0}
                floorViewBox={viewBox}
                fullHeight={true}
                fillColorFormula={this.generateFillColorFormula.bind(this)}
                // apRadiusSizeFormula={this.generateApRadiusSizeFormula()}
                sumOfDevices={0}
                totalUniqueDevices={0}
                showDebug={false}
                apDebugInfo={false}
            />
        </>
    }
}