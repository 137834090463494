const initialState = {
    Malls: {
        selectedMall: 'SMMG',
        mallsInfo : [],
        mallCode : null
    },
    MallAPTimeData: {
        /*
        * <mallIp>: {
        *   <ap>: {
        *       <timeFrom-timeTo>: <int count>
        *   }
        * }
        */
    },
    LoginTokenReducer: {
        token: null,
    }
};

export default initialState;
