import React from 'react';
import '../App.css';
import MallDisplay from "./MallDisplay";
import {faBars, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DashboardEmbed from "./DashboardEmbed";
import {HashRouter as Router, NavLink, Redirect, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import {doLogout} from "../Redux/Actions/actions";
import WifiHotspotsNavBrand from "../Resources/ImageAssets/wifi-dashboard-horizontal-darkbg.png";
import HeatMapMonitoring from "./HeatMapMonitoring"
import PathAnalysisDisplay from "./PathAnalysisDisplay";
import axios from "axios";
import InviteUser, {ModalWrapper} from "./InviteUser";
import TestMap from "./TestMap";


const COMPONENT_REGISTER = {
    DashboardEmbed: DashboardEmbed,
    MallDisplay: MallDisplay,
    HeatMapMonitoring: HeatMapMonitoring,
}


class Main extends React.Component {
    state = {
        tabs: [],
        initialTab: undefined,
        isLoaded: false,
        showModal: false
    }
    componentDidMount() {
        const _this = this;
        axios.get('/api/tab/',).then(function(response) {
            // choose which tab to show first. Priority is landing page. if it doesnt exist, select the first tab
            const initialTab = response.data[0].hasOwnProperty('children') && response.data[0].children ? response.data[0].children[0] : response.data[0];
            const landingPageTab = Object.values(
                response.data
            ).filter(
                o => o.title.toLowerCase().indexOf('landing') >= 0 || o.title.toLowerCase().indexOf('overview') >= 0
            )[0]

            _this.setState({
                tabs: response.data,
                initialTab: landingPageTab ? landingPageTab.front_end_path : initialTab.front_end_path,
                isLoaded: true
            })
        })

        axios.get('/api/me/',).then(function(response) {
            _this.setState({
                me: response.data,
            })
        })
    }
    renderDashboardEmbed(url) {
        return <DashboardEmbed embedUrl={url}/>
    }

    renderComponentFromApi(data) {
        if (COMPONENT_REGISTER.hasOwnProperty(data.front_end_component_name)) {
            const Component = COMPONENT_REGISTER[data.front_end_component_name]
            const props = {};
            if (data.hasOwnProperty('embed_url')) {
                props['embedUrl'] = data.embed_url
            }
            if (props && Object.keys(props).length > 0) {
                return <Component {...props} />
            } else {
                return <Component/>
            }
        }
    }

    handleFeedBackClick(){
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=62caGqdd70K-Wqr02EalZSlqe5x-O1lOiMUc3S3bixBUOE4xQVpLTTlZNElTNDZUUUxINUpaTE9ETy4u","_blank");
    }

    renderFooter() {
        if (this.state.me && this.state.me.permissions && this.state.me.permissions.length > 0) {
            return <>
                { this.state.me.permissions.indexOf('can_invite') >= 0 && this.state.showModal && <ModalWrapper title="Invite User" content={<InviteUser/>} close={() => this.setState({showModal: false})}/> }

                <footer className="footer">
                    <div className="container-fluid text-right text-white">
                        <strong className="pr-5">ADMINISTRATION MENU</strong>
                        { this.state.me.permissions.indexOf('can_invite') >= 0 &&
                        <a href="javascript:void()" className="text-white" onClick={() => this.setState({showModal: true})}>
                            <FontAwesomeIcon icon={faUsers} color="#fff"/> Invite Users
                        </a>}
                    </div>
                </footer>
            </>
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <div data-testid="navbar-loading">Loading...</div>
        }

        return (
            <React.Fragment>
                <Router>
                    <nav className="navbar navbar-expand-lg pr-0" data-testid="header-element">
                        <a className="navbar-brand p-0 m-0" href="/">
                            <img src={WifiHotspotsNavBrand} className="d-inline-block align-top" alt="wifi-hotspots.png" style={{ 'maxHeight' : '30px'}}/>
                        </a>

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} color="#fff"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mr-auto">
                                {this.state.tabs.map(tab => {
                                    if (tab.children) {
                                        return <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                { tab.title }
                                                {tab.subtitle && <p className="nav-subtitle">{tab.subtitle}</p>}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                {tab.children.map( childTab => <NavLink to={childTab.front_end_path} className="dropdown-item">
                                                    {childTab.title}
                                                    {childTab.subtitle && <p className="nav-subtitle">{childTab.subtitle}</p>}
                                                </NavLink>)}
                                            </div>
                                        </li>
                                    } else {
                                        return <li className="nav-item">
                                            <NavLink to={tab.front_end_path} className="nav-link" activeClassName="active">
                                                {tab.title}
                                                {tab.subtitle && <p className="nav-subtitle">{tab.subtitle}</p>}
                                            </NavLink>
                                        </li>
                                    }
                                })}
                            </ul>

                            <div class="my-2 mx-2">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <FontAwesomeIcon icon={faUser}/>
                                </a>
                                <div id="accounts" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <button className={'btn btn-link text-dark'} type={"button"} onClick={this.handleFeedBackClick.bind(this)}>
                                            Feedback
                                        </button>
                                    </li>
                                    <li>
                                        <button className={'btn btn-link text-dark'} type={"button"} onClick={this.props.logout.bind(this)}>
                                            Logout
                                        </button>
                                    </li>
                                </div>
                            </div>
                        </div>

                    </nav>
                    <div className="container-fluid pb-5 mb-5">
                        <Switch>
                            <Redirect exact from="/" to={this.state.initialTab} />
                            {this.state.tabs.map(tab => {
                                if (!tab.children) {
                                    return <Route path={tab.front_end_path}
                                                  component={() => this.renderComponentFromApi(tab)}/>
                                } else {
                                    return tab.children.map(childTab => {
                                        return <Route path={childTab.front_end_path}
                                                      component={() => this.renderComponentFromApi(childTab)}/>
                                    })
                                }
                            })}

                            <Route path='/testmap/' component={TestMap}/>
                        </Switch>

                    </div>


                    {this.renderFooter()}
                </Router>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: state.LoginTokenReducer ? state.LoginTokenReducer.token: null,
    mallsInfo : state.Malls ? state.Malls.mallsInfo : null,
    selectedMall : state.Malls ? state.Malls.selectedMall : null,
    cookies: ownProps.cookies
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(doLogout())
});

Main = connect(mapStateToProps, mapDispatchToProps)(Main);

export default Main;
