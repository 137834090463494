import React from 'react';
import {Circle, Popup} from "react-leaflet";
import TextPath from 'react-leaflet-textpath';
import BaseMap from "./BaseMap";


export default class PathAnalysisMap extends BaseMap {
    state = {
        textPathText: '►  ',
        selectedAp: null
    }

    componentDidMount() {
        const _this = this;
        setInterval(() => {
            const t = _this.state.textPathText;
            _this.setState({
                textPathText: t.substr(t.length - 1, 1) + t.substr(0, t.length -1)
            })
        }, 150)
    }

    componentWillUnmount() {
    }

    smallestDimensionSize() {
        // this assumes that the viewbox is in format "x1 y1 x2 y2";
        const viewBoxList = this.props.floorViewBox.split(' ').map(o => parseInt(o));
        const ySize = viewBoxList[3] - viewBoxList[1];
        const xSize = viewBoxList[2] - viewBoxList[0];

        return ySize > xSize ? xSize : ySize;
    }

    minCircleRadius(smallestDimension) {
        // smallest dimension could be the length or width of the SVG file
        return smallestDimension * 0.02;  // 4%
    }

    _apCoordinates() {
        return Object.values(this.props.floorAccessPoints).reduce( (accumulator, apObject) => {
            const apRawTransform = apObject.coordinates.split(',')
            accumulator[apObject.name] = [-parseFloat(apRawTransform[5].split(')')[0]), parseFloat(apRawTransform[4]) ]
            return accumulator
        }, {})
    }

    handleApClick(apName) {
        this.setState({selectedAp: apName});
    }

    _getCircles() {
        // megamall size in sqm:  67,224.13
        // formula is: Distance = 2*sqrt( C / (L*N*pi) )
        // C = Common Area
        // L = # of Listed APs
        // N = number of devices detected in AP

        const allowedMaxApValue = 100;
        const smallestDimensionSize = this.smallestDimensionSize()
        const minCircleSize = this.minCircleRadius(smallestDimensionSize);

        // FIXME: refactor circle generation into another code; currently ALL circles are recomputed each time we do a color transition for novalues
        return Object.values(this.props.floorAccessPoints).map(ap => {
            const radiusSize = minCircleSize / 2;
            // const xy = this.xy([m[0], m[1]], bounds, horizontalBounds, true, [100,100,100,100]);
            const apRawTransform = ap.coordinates.split(',')
            const xy = [-parseFloat(apRawTransform[5].split(')')[0]), parseFloat(apRawTransform[4]) ]
            if (!radiusSize) {
                return null
            }

            return <Circle
                key={ap.name}
                center={xy}
                radius={radiusSize}
                fillColor="black"
                color={"gray"}
                fillOpacity={1}
                opacity={this.state.selectedAp ? (this.state.selectedAp === ap.name ? 1 : 0.5) : 1}
                weight={1}
                onClick={this.handleApClick.bind(this, ap.name)}
            >
                <Popup>{ap.name}</Popup>
            </Circle>
        });
    }

    calculateMidPoint(point1, point2) {
        return [(point1[0] + point2[0])/2, (point1[1] + point2[1])/2]
    }

    shiftPoint(point) {

        const smallestDimensionSize = this.smallestDimensionSize()
        const minCircleSize = this.minCircleRadius(smallestDimensionSize) / 2;
        return [point[0] + minCircleSize, point[1] + minCircleSize]
    }

    _getPaths() {

        const smallestDimensionSize = this.smallestDimensionSize()
        const minCircleSize = this.minCircleRadius(smallestDimensionSize) / 2;

        const coordinates = this._apCoordinates();
        return Object.values(this.props.floorPaths).map(obj => {
            const positions = [
                coordinates[obj['apname']],
                // this.shiftPoint(this.calculateMidPoint(coordinates[obj['apname']], coordinates[obj['destination_ap']])),
                coordinates[obj['destination_ap']]
            ];
            const weight = parseInt(obj.count)/this.props.pathMaxCount * minCircleSize
            // return <div></div>
            return <TextPath
                positions={positions}
                text={this.state.textPathText}
                repeat
                offset={10}
                smoothFactor={10}
                opacity={this.state.selectedAp ? (this.state.selectedAp === obj.apname ? 1 : 0.1) : 0.25}
                weight={weight}
                attributes={{
                    opacity: this.state.selectedAp ? (this.state.selectedAp === obj.apname ? 1 : 0.5) : 1,
                    style: "font-family: monospace;"
                    // style: "font-size: " + (( parseInt(obj.count)/this.props.pathMaxCount*20) + 10) + 'px;'
                }}
            />
        })
    }

    onPopupClose() {
        this.setState({selectedAp: null})
    }

    renderExtras() {
        let circles = this._getCircles();
        const paths = this._getPaths();
        if (paths.length <= 0) {
            circles = [];
        }

        return <g>
            {paths}
            {circles}
        </g>
    }
}