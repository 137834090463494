import React from 'react';
import './App.css';
import DashboardEmbed from "./Components/DashboardEmbed";
import {connect} from "react-redux";
import Axios from "axios";
import {doLogout} from "./Redux/Actions/actions";
import Login from "./Components/Login"
import Main from "./Components/Main";

class App extends React.Component {
    handleFeedBackClick(){
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=62caGqdd70K-Wqr02EalZSlqe5x-O1lOiMUc3S3bixBUOE4xQVpLTTlZNElTNDZUUUxINUpaTE9ETy4u","_blank");
      }

    render() {
        if (!this.props.token) {
            return <Login/>
        } else {
            const _this = this;
            Axios.defaults.headers.authorization = 'Bearer ' + this.props.token;
            Axios.interceptors.response.use(response => {
                return response;
            }, error => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    _this.props.logout()
                }
                return Promise.reject(error);
            });
        }

        return <Main />;
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: state.LoginTokenReducer ? state.LoginTokenReducer.token: null
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(doLogout())
});

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
