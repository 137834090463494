import React, { PureComponent } from 'react';
import { connect } from 'react-redux'; 
import { faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Axios from "axios";
import ReactTooltip from "react-tooltip";

class APperMall extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            mallName : undefined,
            apStatusList : [],
            isLoading : false,
            countDownTimer : 60
        }

        this.fetchAPLevelStatus = this.fetchAPLevelStatus.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        ReactTooltip.rebuild()
        clearInterval(this.state.intervalId);
        this.setState({ countDownTimer : 60})
        this.fetchAPLevelStatus(nextProps.mallCode)
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    apiCountdownCall() {
        const apiCallInterval = setInterval(() => {
            this.setState(prevState =>{
                return {
                    countDownTimer : prevState.countDownTimer - 1
                }
            },()=>{
                if(this.state.countDownTimer === 0) {
                    this.fetchAPLevelStatus(this.props.mallCode);
                    clearInterval(this.state.intervalId);
                    this.setState({ countDownTimer : 60})
                }
            })
        }, 1000);
        
        this.setState({intervalId: apiCallInterval})
    }

    fetchAPLevelStatus(mallCode) {
        if(mallCode) {
            this.setState({
                isLoading : true
            },() =>{
                Axios.get('/monitoring/malls/'+ mallCode).then(response => {
                    this.setState({
                        mallName : response.data.mallName,
                        apStatusList : response.data.status,
                        isLoading : false
                    },()=> {
                        ReactTooltip.rebuild()
                        if(!this.state.isLoading) {
                            this.apiCountdownCall()
                        }
                    })
                }).catch((error) => {
                    console.info("ERROR",error)
                })
            })
        } else {
            this.setState({
                mallName : undefined,
                apStatusList : [],
            })
        }
    }

    renderStatusCards() {
        ReactTooltip.rebuild()
        if(this.state.apStatusList.length > 0) {

            let apItems = this.state.apStatusList.map(item => {
                return <div className="col-sm-2 mb-4" key={item.apName}>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <FontAwesomeIcon className="float-left mr-2" icon={faCircle}  size='lg' color={item.isAlive ? '#32CD32' : 'red'} data-tip={item.statusMessage} />
                                    {item.apName}
                                </h4>
                            </div>
                        </div>
            })
            return <div className="container-fluid">
                    <div className="row">
                        {apItems}
                    </div>
                    <ReactTooltip type="info" effect="float" backgroundColor="#203a8d"/>
            </div>
        } else {
            return <h6>No Access point detected. Mall may be offline.</h6>
        }
    }

    renderTimer() {
        if(this.state.mallName) {
            return <div className="mb-3"><small>AP data will refresh after {this.state.countDownTimer} seconds</small></div>
        }
    }
    render() {
        let content = <React.Fragment>           
            <h5>{this.state.mallName ? this.state.mallName : "No Selected Mall"}</h5>
            { this.renderTimer() } 
            
            <div  id="ap_per_mall_container">
                {this.renderStatusCards()}
            </div>
        </React.Fragment>

        let loading = <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
        </div>

        return (
            <React.Fragment>
                <div className="container-fluid my-5">
                    <h4>AP per Malls</h4>

                    { this.state.isLoading ?  loading : content }

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = props => ({
    mallCode: props.Malls.mallCode,
});

APperMall = connect(mapStateToProps, null)(APperMall);

export default APperMall