import React, {PureComponent} from 'react';
import MallStatus from './MallStatus';
import APperMall from './APperMall';

class HeatMapMonitoring extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <MallStatus/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <APperMall />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default HeatMapMonitoring