import {createSelector} from "reselect";

const getSelectedMall = state => state.selectedMall;
const getMallsInfo = state => state.mallsInfo;

export const getSelectedMallInfo = createSelector(
    [getSelectedMall, getMallsInfo],
    (selectedMall, mallsInfo) => {
        if (mallsInfo) {
            const r = Object.values(mallsInfo).filter(o => {
                return o.mallCode === selectedMall
            })[0]
            return r;
        }
        return null
    }
)