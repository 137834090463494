import initialState from "../initialState";
import {GET_LOGIN_TOKEN_AUTH, LOGOUT} from "../Actions/actions";

function LoginTokenReducer  (state, action) {
    if(typeof state === 'undefined') {
      state = JSON.parse(JSON.stringify(initialState.LoginTokenReducer))
    }

    if(action.type === GET_LOGIN_TOKEN_AUTH) {
      return {
        ...state, 
        token : action.token
      }
    } else if (action.type === LOGOUT) {
      return {
          ...state,
          token: null
      }
    } else {
      return state
    }
}

export default LoginTokenReducer;